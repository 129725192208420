import React, { useState, useEffect, useContext, useRef } from "react"
import { useMutation, gql } from "@apollo/client"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layout"
import SEO from "../seo"
import EmailCaptureModal from "../email-capture-modal"

import { IntlContext } from "../../intl"

const SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm($input: ContactFormInput!) {
    submitContactForm(input: $input)
  }
`

export const query = graphql`
	query($id: String!) {
		prismicContact(id: { eq: $id }) {
      uid
			url
      lang
			data {
				title {
					text
				}
				content {
					html
				}
        above_the_fold_image {
          alt
          fluid {
            src
          }
        }
				form_success_message
				form_error_message
				form_headline
				name_placeholder
				email_placeholder
				company_placeholder
				message_placeholder
				send_button_text
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicContact {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const ContactPage = ({ data }) => {

	const intl = useIntl()
  const modalTriggerRef1 = useRef()
  const modalTriggerRef2 = useRef()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicContact.lang);
    setAlts(data.prismicContact.alternate_languages || [])
	}, [changeLocale, setAlts, data])
	
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [company, setCompany] = useState('')
	const [message, setMessage] = useState('')

	const [showInvalidName, setShowInvalidName] = useState(false)
	const [showInvalidEmail, setShowInvalidEmail] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [showError, setShowError] = useState(false)

	const submitForm = (e) => {
		e.preventDefault();

		const validName = !!name
		const validEmail = /\S+@\S+\.\S+/.test(email)
		
		if (validName && validEmail) {
			
			setShowInvalidName(false);
			setShowInvalidEmail(false);

			submitContactForm({ variables: {input: { name, email, company, message }} })

		} else {
			setShowSuccess(false);
			setShowError(false);

			if (validName) {
				setShowInvalidName(false)
			} else {
				setShowInvalidName(true)
			}

			if (validEmail) {
				setShowInvalidEmail(false)
			} else {
				setShowInvalidEmail(true)
			}
			
		}
	}

	// submit contact form
  const [submitContactForm, {loading: loadingSubmitContactForm, /*error: errorGetCount*/}] = useMutation(SUBMIT_CONTACT_FORM, {
    onCompleted: (data) => {
			console.log(data)
			
			setName('')
			setEmail('')
			setCompany('')
			setMessage('')
			setShowSuccess(true)
    },
    onError: (error) => {
      // TODO: log error
      // TODO: show error count
      // TODO: fix apollo to throw error after too many attempts or service unreachable!
			console.log(error);
			setShowError(true)
    }
  })
  
  return (
		<Layout container={false}>
			<SEO 
				lang={data.prismicContact.lang} 
				title={data.prismicContact.data.title.text} 
				meta={[{
					"http-equiv": "content-language", 
					content: data.prismicContact.lang
				}]} 
				links={data.prismicContact.alternate_languages.concat([data.prismicContact]).map(alt => ({
					rel: "alternate", 
					hreflang: alt.lang, 
					href: `https://www.customerradius.com${alt.url}`
				})).concat([{
					rel: "alternate", 
					hreflang: "x-default", 
					href: "https://www.customerradius.com/contact"
				}])} />
			<div className="hero is-light">
				<div className="hero-body">
					<div className="container">
						<div className="columns is-vcentered">
							<div className="column is-three-fifths">
								<h1 className="title">{data.prismicContact.data.title.text}</h1>
								<h2 className="subtitle is-hidden-touch">
									<FontAwesomeIcon icon="mobile-alt" />
									{` `}
									(734) 926-4526 &nbsp;
									{` `}
									<FontAwesomeIcon icon="paper-plane" />
									{` `}
									hello@customerradius.com &nbsp;
									{` `}
									{/* <FontAwesomeIcon icon={['fab', 'twitter']} />
									{` `}
									<a href="https://twitter.com/customerradius">@customerradius</a> */}
								</h2>
								<h2 className="subtitle is-hidden-desktop">
									(734) 926-4526 {` `} <FontAwesomeIcon icon="mobile-alt" />
									<br />
									hello@customerradius.com {` `} <FontAwesomeIcon icon="paper-plane" />
									<br />
									{/* <a href="https://twitter.com/customerradius">@customerradius</a> {` `} <FontAwesomeIcon icon={['fab', 'twitter']} /> */}
								</h2>
								<button className="button is-warning is-medium" ref={modalTriggerRef1}>
									{intl.formatMessage({ id: "email_capture.request_early_access" })}
								</button>
								<EmailCaptureModal triggerRef={modalTriggerRef1} intl={intl} sourceDetail={'Contact'} />
							</div>
							<div className="column is-two-fifths">
								<figure className="image">
									<img src={data.prismicContact.data.above_the_fold_image.fluid.src}
										alt={data.prismicContact.data.above_the_fold_image.alt} />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section">
				<div className="container">
					<div className="columns">
						<div className="column is-5">
							<div className="content is-medium" dangerouslySetInnerHTML={{ __html: data.prismicContact.data.content.html }}></div>
						</div>
						<div className="column is-5 is-offset-1">
							<h2 className="subtitle is-size-3">
								{data.prismicContact.data.form_headline}
							</h2>
							
							<form onSubmit={submitForm}>
								<div className="field">
									<label className="label">{data.prismicContact.data.name_placeholder+' *'}</label>
									<div className="control">
										<input className={showInvalidName ? 'input is-danger' : 'input'} type="text" name="name" maxLength="50"
											placeholder={data.prismicContact.data.name_placeholder}
											value={name}
											onChange={e=>setName(e.target.value)} />
											{showInvalidName &&
												<p className="help is-danger">{intl.formatMessage({ id: "contact_form.name_required" })}</p>
											}
									</div>
								</div>
								<div className="field">
									<label className="label">{data.prismicContact.data.email_placeholder+' *'}</label>
									<div className="control">
										<input className={showInvalidEmail ? 'input is-danger' : 'input'} type="text" name="email" maxLength="50" 
											placeholder={data.prismicContact.data.email_placeholder}
											value={email}
											onChange={e=>setEmail(e.target.value)} />
											{showInvalidEmail &&
												<p className="help is-danger">{intl.formatMessage({ id: "contact_form.valid_email_required" })}</p>
											}
									</div>
								</div>
								<div className="field">
									<label className="label">{data.prismicContact.data.company_placeholder}</label>
									<div className="control">
										<input className="input" type="text" name="company" maxLength="50" 
											placeholder={data.prismicContact.data.company_placeholder} 
											value={company}
											onChange={e=>setCompany(e.target.value)} />
									</div>
								</div>
								<div className="field">
									<label className="label">{data.prismicContact.data.message_placeholder+' *'}</label>
									<div className="control">
										<textarea className="textarea" name="message" maxLength="500"
											placeholder={data.prismicContact.data.message_placeholder}
											value={message}
											onChange={e=>setMessage(e.target.value)}></textarea>
									</div>
								</div>
								{showSuccess && 
									<div className="notification is-success">
										<button className="delete" name="success" aria-label="close" onClick={()=>setShowSuccess(false)}></button>
										{data.prismicContact.data.form_success_message}
									</div>
								}
								{showError &&
									<div className="notification is-danger">
										<button className="delete" name="error" aria-label="close" onClick={()=>setShowError(false)}></button>
										{data.prismicContact.data.form_error_message}
									</div>
								}
								<div className="field">
									<div className="control has-text-centered">
										<button className={loadingSubmitContactForm?"button is-rounded is-info is-medium is-loading":"button is-rounded is-info is-medium"} type="submit">
											{data.prismicContact.data.send_button_text}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<section className="section">
				<div className="container">
					<div className="columns">
						<div className="column">
							{/* Showcase thought leadership - list of recent blog posts, articles about the company in the press */}
						</div>
						<div className="column">
							{/* Presentation or events chedule */}
						</div>
					</div>
				</div>
			</section>
			<div className="hero is-light">
				<div className="hero-body has-text-centered">
					<button className="button is-warning is-large" ref={modalTriggerRef2}>
						{intl.formatMessage({ id: "email_capture.request_early_access" })}
					</button>
					<EmailCaptureModal triggerRef={modalTriggerRef2} intl={intl} sourceDetail={'Contact'} />
				</div>
			</div>
		</Layout>
)}

export default ContactPage
